import axios from 'axios';
import Alert from '../global/Alert.js';
import { commonHeaders, csrfHeaderName, csrfToken, shouldApplyCsrf } from './commonXHRHeaders';
import { reloadPage } from './helpers/misc';
import { getTranslationFromStore } from './language';
import { isObject } from './helpers/base';
Object.entries(commonHeaders).forEach(([key, value]) => {
    axios.defaults.headers.common[key] = value;
});
['patch', 'post', 'put'].forEach(method => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    axios.defaults.headers[method]['Content-Type'] = 'application/json';
});
axios.defaults.responseType = 'json';
axios.defaults.validateStatus = status => status <= 500 && status < 300;
axios.interceptors.request.use(config => {
    if (shouldApplyCsrf(config.url)) {
        config.headers[csrfHeaderName] = csrfToken;
    }
    return config;
});
axios.interceptors.response.use(response => response, (error) => {
    var _a, _b;
    if (isObject(error.response)) {
        const response = error.response;
        if (response.status === 419) {
            const message = getTranslationFromStore('axios.error.csrf_message', 'The page has expired. Refresh the page to continue. You might have to log in again.');
            void Alert.message(message, getTranslationFromStore('axios.error.csrf_title', 'Page expired'), 'info', {
                [getTranslationFromStore('axios.error.button_text', 'Refresh the page')]: () => {
                    reloadPage();
                    return false;
                },
            });
            /* eslint-disable @typescript-eslint/ban-ts-comment */
            // @ts-ignore
            error.dont_show_xhr_alert = true;
        }
        else if (response.status === 401) {
            const data = isObject(response.data) ? response.data : {};
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const success = (_a = data.success) !== null && _a !== void 0 ? _a : false;
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const message = (_b = data.message) !== null && _b !== void 0 ? _b : '';
            if (!success && message === 'Unauthorized') {
                void Alert.message('Your session has been expired.', 'Session expired', 'info', {
                    ['Sign in']: () => {
                        location.reload();
                        return false;
                    },
                });
                /* eslint-disable @typescript-eslint/ban-ts-comment */
                // @ts-ignore
                error.dont_show_xhr_alert = true;
            }
        }
    }
    return Promise.reject(error);
});
export default axios;
