var _a;
export const csrfElement = document.head.querySelector('meta[name="csrf-token"]');
if (!(csrfElement === null || csrfElement === void 0 ? void 0 : csrfElement.content)) {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}
export const csrfHeaderName = 'X-CSRF-TOKEN';
export const csrfToken = (_a = csrfElement === null || csrfElement === void 0 ? void 0 : csrfElement.content) !== null && _a !== void 0 ? _a : '';
export const commonHeaders = {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
};
export const shouldApplyCsrf = (url) => {
    if (!url) {
        return false;
    }
    try {
        const requestedHost = (new URL(url)).hostname;
        if (requestedHost.endsWith(window.__INITIAL_STATE__.config.app.host)) {
            return true;
        }
    }
    catch ( /* Do nothing */_a) { /* Do nothing */ }
    return false;
};
