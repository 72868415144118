export default class EventBus {
    constructor() {
        this.eventTarget = document.appendChild(document.createComment('event-bus'));
    }
    static init() {
        window.eventBus = new EventBus();
    }
    on(type, listener) {
        this.eventTarget.addEventListener(type, (e) => listener(e));
        return this;
    }
    once(type, listener) {
        this.eventTarget.addEventListener(type, (e) => listener(e), { once: true });
        return this;
    }
    off(type, listener = null) {
        this.eventTarget.removeEventListener(type, listener);
        return this;
    }
    emit(type, detail) {
        this.eventTarget.dispatchEvent(new CustomEvent(type, { detail }));
        return this;
    }
}
