export default class Feature {
    constructor() {
        this.features = window.__INITIAL_STATE__.features;
    }
    static init() {
        window.feature = new Feature();
    }
    active(feature) {
        var _a;
        if (!this.features) {
            return false;
        }
        return Boolean((_a = this.features[feature]) !== null && _a !== void 0 ? _a : false);
    }
}
