var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { commonHeaders, csrfHeaderName, csrfToken, shouldApplyCsrf } from './commonXHRHeaders';
import { getAjaxErrorMessageAsString, showInfoAlert, showWarningAlert, showXhrErrorAlert } from './helpers/alerts.js';
import { isObject } from './helpers/base';
const isUnauthorized = (status, success, message) => {
    if (status === 401) {
        return true;
    }
    return !success && message === 'Unauthorized';
};
const { 'Content-Type': contentType } = commonHeaders, headers = __rest(commonHeaders, ['Content-Type']);
const ajaxSettings = {
    headers: Object.assign({}, headers),
    beforeSend: (xhr, settings) => {
        if (shouldApplyCsrf(settings.url)) {
            xhr.setRequestHeader(csrfHeaderName, csrfToken);
        }
    },
    error: (response) => {
        var _a, _b;
        if (response.status >= 500) {
            void getAjaxErrorMessageAsString(response).then((message) => {
                console.error(`Server error: ${message}`, response);
            });
            void showXhrErrorAlert(response, {
                title: 'Server error',
            });
            return;
        }
        if (response.status === 419) {
            void showWarningAlert('The csrf tokens do not match', {
                title: 'Session expired',
                buttons: {
                    ['Sign in']: () => {
                        location.reload();
                        return false;
                    },
                },
            });
        }
        const json = isObject(response.responseJSON) ? response.responseJSON : {};
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        if (isUnauthorized(response.status, Boolean((_a = json === null || json === void 0 ? void 0 : json.success) !== null && _a !== void 0 ? _a : false), String((_b = json === null || json === void 0 ? void 0 : json.message) !== null && _b !== void 0 ? _b : ''))) {
            void showInfoAlert('Your session has been expired.', {
                title: 'Session expired',
                buttons: {
                    ['Sign in']: () => {
                        location.reload();
                        return false;
                    },
                },
            });
        }
    },
};
window.$ = jQuery;
window.$.ajaxSetup(ajaxSettings);
