$('[data-collapse-trigger]').on('click', function (event) {
    const $header = $(event.currentTarget);
    const $target = $($header.data('collapse-trigger'));
    const isCollapsed = $target.data('collapsed');

    toggleSvgClass($header.find('.plus[data-collapse-icon]'), 'tw-hidden', isCollapsed);
    toggleSvgClass($header.find('.minus[data-collapse-icon]'), 'tw-hidden', ! isCollapsed);

    $target.data('collapsed', ! isCollapsed);
    $target.toggleClass('tw-hidden', ! isCollapsed);
    $header.toggleClass('text-white', ! isCollapsed).toggleClass('text-deep-blue-200', isCollapsed);
});

/**
 * This method used "attr('class')" to toggle the classname (instead of toggleClass)
 *
 * Since we're still using jQuery 1 it's a workaround to toggle classes on SVG elements.
 * jQuery 1 / 2 don't support methods like toggleClass/addClass on SVG elements, but "attr('class')" does work.
 *
 * @param {object} $element The jQuery element
 * @param {string} className The classname that should be toggled
 * @param {boolean} add Indicates if the class should be added or removed
 */
function toggleSvgClass($element, className, add) {
    const classList = $element.attr('class').split(' ');

    if (classList.includes(className) && ! add) {
        const index = classList.indexOf(className);

        if (index > -1) {
            classList.splice(index, 1);
        }
    }

    if (! classList.includes(className) && add) {
        classList.push(className);
    }

    $element.attr('class', classList.join(' '));
}
