/**
 * Enum number values.
 * @enum {number}
 */
export const VideoStatus = Object.freeze({
    OFFLINE: 0,
    EMBED_ONLY: 1,
    UNLISTED: 2,
    PUBLISHED: 3,

    ALLOWED_VIEWERS_ONLY: 4,
    PROCESSING: 5,
    FAILED: 6,

    isHiddenFromPublic(value) {
        return [
            this.OFFLINE,
            this.EMBED_ONLY,
            this.ALLOWED_VIEWERS_ONLY,
        ].includes(value);
    },

    canBeViewedByPlayerUrl(value) {
        return ! this.isHiddenFromPublic(value);
    },

    isNotEmbeddable(value) {
        return [
            this.OFFLINE,
            this.ALLOWED_VIEWERS_ONLY,
        ].includes(value);
    },

    isAllowedViewersOnly(value) {
        return value === this.ALLOWED_VIEWERS_ONLY;
    },

    isEmbeddable(value) {
        return [
            this.EMBED_ONLY,
            this.UNLISTED,
            this.PUBLISHED,
        ].includes(value);
    },

    isUnpublished(value) {
        return [
            this.OFFLINE,
            this.PROCESSING,
            this.FAILED,
        ].includes(value);
    },

    canBeEmbedded(value) {
        return ! this.isNotEmbeddable(value);
    },

    isPendingUpload(value) {
        return [
            this.PROCESSING,
            this.FAILED,
        ].includes(value);
    },
});
