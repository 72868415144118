import 'bootstrap/js/tooltip';
import 'bootstrap/js/tab';
import 'bootstrap/js/dropdown';
import 'bootstrap/js/collapse';
import '../global/datatables.bundle';
import EventBus from './EventBus';
import Feature from './Feature';
import './footer.js';
import { asyncConfirm, getAjaxErrorMessageAsString, showErrorAlert, showInfoAlert, showStandardSaveNotice, showXhrErrorAlert, } from './helpers/alerts.js';
import './info-popup.js';
import './jquery-setup';
import { __ } from './language';
import { enableProvider, validateSubtitle } from './helpers/misc';
import { VideoStatus } from '../enums/VideoStatus';
/**
 * jquery-ui/ui/widgets/slider and jquery-tokeninput are required in global.ts for the video settings slider and tabs,
 * has to be defined here to be inside the JQuery init scope
 */
import 'jquery-ui/ui/widgets/slider';
import 'jquery-tokeninput';
/**
 * use `.defineProperty()` to prevent the custom method from being enumerable
 *
 * @see https://stackoverflow.com/questions/56168498/javascript-why-does-the-for-in-loop-pick-variables-from-proto
 */
Object.defineProperty(Array.prototype, 'first', {
    value: function () {
        const [first = null] = this;
        return first;
    },
});
Object.defineProperty(String.prototype, 'trunc', {
    value: function (length, append = '\u2026') {
        if (this.length > length) {
            return this.substring(0, length) + append;
        }
        return this.valueOf();
    },
});
EventBus.init();
Feature.init();
window.showErrorAlert = showErrorAlert;
window.showXhrErrorAlert = showXhrErrorAlert;
window.showInfoAlert = showInfoAlert;
window.showStandardSaveNotice = showStandardSaveNotice;
window.asyncConfirm = asyncConfirm;
window.getAjaxErrorMessageAsString = getAjaxErrorMessageAsString;
window.__ = __;
window.enableProvider = enableProvider;
window.validateSubtitle = validateSubtitle;
window.videoStatus = VideoStatus;
