var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { isNullOrUndefined } from './base';
import { __ } from '../language';
import { parseSync } from 'subtitle';
export const PARSE_INT_RADIX = 10;
export function interactionHasElement(interaction) {
    return !isNullOrUndefined(interaction) && !isNullOrUndefined(interaction.$element);
}
export function getGlyphicon(glyphicon) {
    return $('<span>').addClass('glyphicons ' + glyphicon);
}
export function getGlyphiconText(glyphicon, name) {
    var _a;
    return ((_a = getGlyphicon(glyphicon).get(0)) === null || _a === void 0 ? void 0 : _a.outerHTML) + ' ' + name;
}
export function getHiHaHoIcon(icon) {
    return $('<span>').addClass('hihaho-icon ' + icon);
}
export function getHiHaHoIconText(icon, name) {
    var _a;
    return ((_a = getHiHaHoIcon(icon).get(0)) === null || _a === void 0 ? void 0 : _a.outerHTML) + ' ' + name;
}
export function getFontAwesomeCustomIcon(icon) {
    return $('<span>').addClass(`fa-kit ${icon}`);
}
export function getFontAwesomeCustomIconText(icon, name) {
    var _a;
    return ((_a = getFontAwesomeCustomIcon(icon).get(0)) === null || _a === void 0 ? void 0 : _a.outerHTML) + ' ' + name;
}
export function safeJsonParse(data) {
    try {
        return JSON.parse(data);
    }
    catch (e) {
        return null;
    }
}
export function capitalize(text) {
    return text.charAt(0).toUpperCase() + text.slice(1);
}
export function addInteractionTextClassToContent($element) {
    recursivelyAddClassToFontSizeSpans($element, 'interactionText');
}
/**'
 * @private
 */
function recursivelyAddClassToFontSizeSpans($element, classString) {
    $element.find('span[style*="font-size"]').each((i, span) => {
        const $span = $(span);
        $span.html(`<span class="${classString}">${$span.html()}</span>`);
        recursivelyAddClassToFontSizeSpans($span);
    });
}
/**
 * Reload the page without POSTDATA
 * Using location.reload() could cause a resubmission of POSTDATA and a possible browser notice
 * @see https://stackoverflow.com/questions/570015/how-do-i-reload-a-page-without-a-postdata-warning-in-javascript
 */
export function reloadPage() {
    var _a;
    window.location.href = (_a = window.location.href.split('#').first()) !== null && _a !== void 0 ? _a : window.location.href;
}
export function loadScript(url, callback = null, errorCallback = null) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    // Several events for cross browser compatibility.
    script.onload = callback;
    script.onerror = errorCallback;
    document.head.appendChild(script);
}
export function onceReady(conditionalCallback, maxDuration = 5000, checkIntervalMs = 20) {
    return new Promise((resolve, reject) => {
        if (conditionalCallback()) {
            return resolve();
        }
        const startTime = new Date().getTime();
        const waitForReadyInterval = setInterval(() => {
            if (conditionalCallback()) {
                clearInterval(waitForReadyInterval);
                return resolve();
            }
            if (new Date().getTime() - startTime > maxDuration) {
                clearInterval(waitForReadyInterval);
                reject(`onceReady timed out after ${maxDuration} ms`);
            }
        }, checkIntervalMs);
    });
}
export function onceFinishedSeeking(maxDuration = 4000, checkIntervalMs = 20) {
    return onceReady(() => { var _a; return !((_a = window.HiHaHoPlayer.platform) === null || _a === void 0 ? void 0 : _a.playerControls.is_seeking); }, maxDuration, checkIntervalMs);
}
export function enableProvider(name, containerId, connectionName) {
    return __awaiter(this, void 0, void 0, function* () {
        const translations = yield __([
            'ClickToDisconnect',
            'Connected',
            'Disconnect',
        ]);
        connectionName = isNullOrUndefined(connectionName) ? translations.connected : connectionName;
        const $buttons = $(`.video-container[data-container-id='${containerId}'] .form-group[data-platform='${name}'] .buttons`);
        $buttons.empty();
        $('<span>').addClass('glyphicons ok text-success').appendTo($buttons);
        $('<span>').text(` ${connectionName}`).appendTo($buttons);
        $('<a>')
            .addClass('disconnectService')
            .attr('href', '#')
            .attr('title', translations.clicktodisconnect)
            .text(` (${translations.disconnect})`)
            .appendTo($buttons);
        const $modal = $(`#${name}Modal`);
        if ($modal.length) {
            $modal.modal('hide');
        }
    });
}
export function inlineHidden(element, hide) {
    if (!element) {
        return;
    }
    if (hide) {
        element.style.display = 'none';
    }
    else if (element.style.display === 'none') {
        element.style.removeProperty('display');
    }
}
export function validateSubtitle(subtitle) {
    return new Promise((resolve, reject) => {
        try {
            resolve(parseSync(subtitle));
        }
        catch (e) {
            reject(e);
        }
    });
}
