import 'bootstrap/js/popover';

$(function () {
    const $infoPopups = $('.info-popup');

    $infoPopups.each((i, el) => {
        const $this = $(el);
        const $contentDiv = $($this.attr('data-info-ref'));

        const showPopOver = () => {
            $infoPopups.not($this).popover('hide');
            $(window)
                .off('click')
                .on('click', () => {
                    $this.popover('hide');
                    $(window).off('click');
                });
        };

        $this
            .popover({
                trigger: 'focus',
                html: true,
                animation: true,
                template: `
                    <div class="popover information_popover" role="tooltip">
                        <div class="arrow"></div>
                        <h3 class="popover-title"></h3>
                        <div class="popover-content"></div>
                    </div>
                `,
                content: $contentDiv.html(),
                title: '<span class="glyphicons info_sign"></span> ' + $contentDiv.attr('data-title'),
                container: 'body',
                placement: $this.attr('data-info-placement') ? $this.attr('data-info-placement') : 'auto right',
            })
            .on('show.bs.popover', () => showPopOver());

        $this
            .parent()
            .off('click')
            .on('click', e => {
                $this.popover('toggle');
                e.stopPropagation();
            });

        $this.off('click').on('click', e => {
            e.preventDefault();
            e.stopPropagation();
            $this.popover('toggle');
        });
    });
});
