import DOMPurify from 'dompurify';

import { callIfFunction, isValidHttpUrl, nl2br } from './helpers/base';
import { __ } from './language';
import { capitalize } from './helpers/misc';

let timeout = null;
let activeElement = null;
let $container = null;
let $body = null;

/**
 * @type {CallableFunction}
 */
let onRemoveCallback = undefined;

const setOnRemoveCallback = callback => {
    if (typeof callback === 'function') {
        onRemoveCallback = callback;
    } else {
        onRemoveCallback = undefined;
    }
};

const callOnRemoveCallback = () => {
    if (typeof onRemoveCallback === 'function') {
        onRemoveCallback();
    }
};

const getBody = () => {
    if ($body === null) {
        $body = $('body');
    }
    return $body;
};

const escapeKeyHandler = e => {
    if (e.code === 'Escape') {
        e.preventDefault();

        Alert.remove();

        return false;
    } else if ($container.find('.alert-window-buttons button').length === 1 && e.code === 'Enter') {
        e.preventDefault();

        $container('.alert-window-buttons button').trigger('click');

        return false;
    }
};

const create = async (
    $element,
    title = null,
    type = 'info',
    buttons = null,
    onRemoveCallback = undefined,
) => {
    //remove focus
    activeElement = $(document.activeElement).trigger('blur');

    await Alert.remove();

    setOnRemoveCallback(onRemoveCallback);

    getBody();

    $container = $('<div>').attr('id', 'AlertOverlay')
        .addClass(type)
        .appendTo($body);

    $('<div>').addClass('backdrop')
        .appendTo($container)
        .on('click', () => Alert.remove());

    const $popover = $('<div>').addClass('popover alert-window').appendTo($container);

    const $title = $('<h3>').addClass('popover-title')
        .text(title ?? await __(capitalize(type)))
        .appendTo($popover);

    $('<button type="button">').addClass('close')
        .html('&times;')
        .appendTo($title)
        .on('click', () => Alert.remove());

    const $content = $('<div>')
        .addClass('popover-content')
        .empty()
        .append($element)
        .appendTo($popover);

    const $buttonContainer = $('<div>')
        .addClass('alert-window-buttons')
        .appendTo($content);

    Object.entries(buttons ?? {[await __('Ok')]: null}).forEach(([text, obj]) => {
        const link = obj?.link ?? null;

        let callback;
        if (typeof obj === 'function') {
            callback = obj;
        } else {
            callback = obj?.callback ?? null;
        }

        const btnClass = obj?.class ?? 'default';

        let $button;
        if (! link) {
            $button = $('<button>')
                .attr('type', 'button')
                .addClass(`btn btn-${ btnClass }`);
        } else {
            $button = $('<a>')
                .attr({href: link, target: '_blank'})
                .addClass(`btn btn-${ btnClass }`);
        }

        $button
            .attr('tabindex', '2')
            .append(document.createTextNode(text))
            .on('click', () => {
                if (isValidHttpUrl(callback)) {
                    location.href = callback;
                    return;
                }

                setOnRemoveCallback(null);

                Alert.remove().then(() => callIfFunction(callback));
            })
            .appendTo($buttonContainer);

    });

    $container.fadeIn().focus();
    $(document).on('keydown', escapeKeyHandler);
};

export default class Alert {
    static closeAfterTimeout(ms = 5000) {
        return new Promise(resolve => {
            timeout = setTimeout(() => this.remove().then(() => resolve()), ms);
        });
    }

    static async message(message, title, type, buttons, onRemoveCallback = undefined) {
        await create(
            $('<p>').html(nl2br(message)),
            title,
            type,
            buttons,
            onRemoveCallback,
        );
    }

    static async html(dirtyHtml, title, type, buttons, sanitize = true) {
        const html = sanitize ? DOMPurify.sanitize(dirtyHtml, {USE_PROFILES: {html: true}}) : dirtyHtml;

        await create($('<div>').html(html), title, type, buttons);
    }

    static isActive() {
        return $container !== null;
    }

    static remove() {
        return new Promise(resolve => {
            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
            }

            $(document).off('keydown', escapeKeyHandler);

            //reset focus on element
            if (activeElement !== null) {
                activeElement.focus();
            }
            activeElement = null;

            if (! this.isActive()) {
                resolve();
                return;
            }

            $container?.fadeOut(() => {
                // Container could be removed during the fadeOut
                if ($container) {
                    $container.remove();
                    $container = null;
                }

                callOnRemoveCallback();

                resolve();
            });
        });
    }
}

window.Alert = Alert;
