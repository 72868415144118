//custom bootstrap datatables
import 'datatables/media/js/jquery.dataTables';
import 'datatables.net-autofill';
import 'datatables.net-bs'; // has to be after jquery.dataTables
import 'datatables.net-buttons';
import 'datatables.net-fixedheader';
import 'datatables.net-responsive';
import 'datatables.net-select';
import 'datatables.net-bs/css/dataTables.bootstrap.css';
import '../../css/datatables-hihaho.css';
