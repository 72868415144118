import DOMPurify from 'dompurify';
// Add a default hook to the sanitizer to add target and rel to all elements
// DOMPurify doesn't do this by default and will just use links wihtout _blank https://github.com/cure53/DOMPurify/issues/317
DOMPurify.addHook('afterSanitizeAttributes', node => {
    if (!(node instanceof HTMLAnchorElement)) {
        return;
    }
    node.setAttribute('target', '_blank');
    node.setAttribute('rel', 'noopener noreferrer');
});
export function isNullOrUndefined(element) {
    return typeof element === 'undefined' || element === null;
}
export function isTrue(variable) {
    if (isNullOrUndefined(variable)) {
        return false;
    }
    return variable === true || variable === 'true';
}
export function isFunction(variable) {
    return typeof variable === 'function';
}
export function isObject(val) {
    return val !== null && typeof val === 'object';
}
export function callIfFunction(callback, args = {}) {
    if (!isFunction(callback)) {
        return false;
    }
    return callback(args);
}
export function convertToBoolean(value) {
    return !!value;
}
export function richText(text, convertNewlines = false, tags = ['br', 'strong', 'a', 'span', 'em', 'ol', 'ul', 'li', 'p']) {
    const sanitized = DOMPurify.sanitize(text, {
        ALLOWED_TAGS: tags
    });
    return `<div class="rich-text">${convertNewlines ? nl2br(sanitized, tags) : sanitized}</div>`;
}
export const richTextPrefix = '<!--TINYMCE-->';
export function nl2br(str, allowedTags = ['br']) {
    if (typeof str !== 'string') {
        return '';
    }
    const dirty = str.replace(/(?:\\[rn]|[\r\n]+)+/g, '<br>');
    return DOMPurify.sanitize(dirty, { ALLOWED_TAGS: allowedTags });
}
export function isBoolTrue(value) {
    return value === '1' || value === 1 || value === 'true' || value === true;
}
export function isValidHttpUrl(string) {
    let url;
    try {
        url = new URL(string);
    }
    catch (e) {
        return false;
    }
    return url.protocol === 'http:' || url.protocol === 'https:';
}
export function getParameterByName(name, url = window.location.href) {
    let search = url;
    try {
        search = new URL(url).search;
    }
    catch (e) {
        //
    }
    return new URLSearchParams(search).get(name);
}
export function truncate(value, length, append = '\u2026') {
    if (typeof value !== 'string') {
        return '';
    }
    return value.trunc(length, append);
}
export function stripHtml(content, allowedTags = []) {
    return DOMPurify.sanitize(content, {
        USE_PROFILES: { html: false },
        ADD_TAGS: allowedTags,
    });
}
export function isValidURL(hostname) {
    const regExp = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)/g;
    return hostname.match(regExp) !== null;
}
