var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { isNullOrUndefined } from './helpers/base';
import { route } from 'ziggy-js';
import axios from './axios';
import { reactive } from '@vue/reactivity';
export function __(keys) {
    return __awaiter(this, void 0, void 0, function* () {
        if (isNullOrUndefined(keys)) {
            return '';
        }
        if (!(keys instanceof Array)) {
            const { value } = yield getTranslationBySingle(translationKeyToLowercase(keys));
            return value;
        }
        const translations = yield Promise.all(keys.map((key) => __awaiter(this, void 0, void 0, function* () { return yield getTranslationBySingle(translationKeyToLowercase(key)); })));
        return translations.reduce((carry, translation) => {
            carry[translation.key] = translation.value;
            return carry;
        }, {});
    });
}
const translations = reactive(window.__INITIAL_STATE__.translations.strings);
function isUninitialized() {
    return typeof window.__INITIAL_STATE__.translations === 'undefined'
        || typeof window.__INITIAL_STATE__.translations.strings === 'undefined';
}
function translationKeyToLowercase(key) {
    return String(key).toLowerCase();
}
function getTranslationBySingle(key) {
    return new Promise(resolve => {
        if (isUninitialized()) {
            console.warn('langStrings not yet initialised');
            resolve({
                key,
                value: key,
            });
            return;
        }
        if (hasTranslationInStore(key)) {
            resolve({
                key,
                value: getTranslationFromStore(key),
            });
            return;
        }
        if (window.__INITIAL_STATE__.config.env === 'testing' || window.__INITIAL_STATE__.translations.driver === 'none') {
            const value = `!${key}!`;
            setTranslationInStore(key, value);
            resolve({
                key,
                value,
            });
            return;
        }
        axios.post(route('js.translation.store', { key }), {
            player: typeof window.editor !== 'object' && window.__INITIAL_STATE__.translations.is_video_player,
        })
            .then((response) => {
            setTranslationInStore(key, response.data);
            resolve({
                key,
                value: response.data,
            });
        })
            .catch((error) => { var _a; return console.warn((_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.data); });
    });
}
function hasTranslationInStore(key) {
    if (isUninitialized()) {
        return false;
    }
    return typeof translations[key] !== 'undefined';
}
export function getTranslationFromStore(key, fallback = key) {
    return translations[key.toLowerCase()] || fallback;
}
window.getTranslationFromStoreWindow = getTranslationFromStore;
function setTranslationInStore(key, value) {
    translations[key.toLowerCase()] = value;
}
