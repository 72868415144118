import 'toastify-js/src/toastify.css';
import Alert from '../Alert';
import DOMPurify from 'dompurify';
import Toastify from 'toastify-js';
import { __, getTranslationFromStore } from '../language';
import { getHiHaHoIconText, safeJsonParse } from './misc';

export async function showOutOfPrepaidVideosAlert(message, config = {}) {
    const translations = await __([
        'Cancel',
        'OutOfPrepaidVideosAlertBuyVideosCta',
        'OutOfPrepaidVideosWarningAlertTitle',
    ]);

    await showInfoAlert(message, {
        title: translations.outofprepaidvideoswarningalerttitle,
        ...config,
        buttons: {
            [translations.cancel]: null,
            [translations.outofprepaidvideosalertbuyvideoscta]: {
                class: 'btn btn-cta',
                callback: () => $('#purchaseVideosModal').modal('show'),
            },
            ...config.buttons,
        },
    });
}

export async function showXhrErrorAlert(jqXHR, config = {}) {
    if (jqXHR?.dont_show_xhr_alert) {
        return;
    }

    await showErrorAlert(await getAjaxErrorMessageAsString(jqXHR, '<br/>', config.defaultMessage ?? null), config);
}

export async function showXhrWarningAlert(jqXHR, config = {}) {
    if (jqXHR?.dont_show_xhr_alert) {
        return;
    }

    await showWarningAlert(await getAjaxErrorMessageAsString(jqXHR), config);
}

export async function showErrorAlert(message, config = {}) {
    await showAlert(message, {
        type: 'danger',
        title: getTranslationFromStore('Error'),
        ...config,
    });
}

export async function showWarningAlert(message, config = {}) {
    await showAlert(message, {
        type: 'warning',
        title: getTranslationFromStore('Warning'),
        ...config,
    });
}

export async function showInfoAlert(message, config = {}) {
    await showAlert(message, {
        type: 'info',
        title: getTranslationFromStore('Info'),
        ...config,
    });
}

export async function showAlert(message, config = {}) {
    await Alert.message(
        message,
        config.title || getTranslationFromStore('Unknown'),
        config.type,
        config.buttons,
    );

    if (config.fade) {
        await Alert.closeAfterTimeout();
    }
}

/**
 * @public
 * @param {string} message
 * @param {object} [config={}]
 * @returns {Promise<boolean>}
 */
export function asyncConfirm(message, config = {}) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async resolve => {
        let continueClass;
        switch (config.type) {
            case 'success':
                continueClass = 'theme-btn theme-btn-success';
                break;
            case 'danger':
            case 'warning':
                continueClass = 'theme-btn theme-btn-danger';
                break;
            default:
                continueClass = 'theme-btn theme-btn-primary';
                break;
        }

        config = {
            buttons: {
                [config.cancelText || getTranslationFromStore('cancel')]: {
                    class: 'theme-btn theme-btn-cancel',
                    callback: () => resolve(false),
                },
                [config.continueText || getTranslationFromStore('continue')]: {
                    class: continueClass,
                    callback: () => resolve(true),
                },
            },
            ...config,
        };

        await Alert.message(
            message,
            config.title || getTranslationFromStore('Unknown'),
            config.type,
            config.buttons,
            () => resolve(false),
        );
    });
}

/**
 * @public
 * @returns {Promise<any>}
 */
export async function getAjaxErrorMessageAsString(response, glue = '<br/>', defaultMessage = null) {
    if (typeof response === 'undefined' || ! response) {
        return defaultMessage ?? getTranslationFromStore('UnknownError');
    }

    if (response.responseJSON) {
        response = response.responseJSON;
    }

    if (response.data) {
        response = response.data;
    }

    if (response.isAxiosError) {
        response = response.response.data;
    }

    if (response.errors) {
        const message = getDeepErrorMessage(response.errors, glue);
        if (message) {
            return message;
        }
    }

    if (response.warnings) {
        const message = getDeepErrorMessage(response.warnings, glue);
        if (message) {
            return message;
        }
    }

    if (response.message) {
        return response.message;
    }

    if (response.responseText) {
        const jsonData = safeJsonParse(response.responseText);

        if (jsonData) {
            return await getAjaxErrorMessageAsString(jsonData, glue);
        }

        return response.responseText;
    }

    if (response.error) {
        return response.error;
    }

    if (response.statusText) {
        return response.statusText;
    }

    return defaultMessage ?? getTranslationFromStore('UnknownError');
}

/**
 * @private
 * @param errors
 * @param glue
 * @return {string|null}
 */
function getDeepErrorMessage(errors, glue) {
    if (typeof errors === 'undefined' || ! errors || ! (errors instanceof Object)) {
        return null;
    }

    if (! Array.isArray(errors)) {
        errors = Object.values(errors);
    }

    if (! errors.length) {
        return null;
    }

    return errors.reduce((errorMessage, errors) => {
        if (typeof errors === 'string') {
            return `${ errorMessage }${ errors }${ glue }`;
        }

        return errors.reduce((acc, message) => `${ acc }${ message }${ glue }`, errorMessage);
    }, '');
}

export function showStandardSaveNotice(content = getHiHaHoIconText('floppy', 'Saved successfully')) {
    showSuccessToast(content);
}

export function showSuccessToast(content) {
    showToast(content, '#196542');
}

export function showWarningToast(content) {
    showToast(content, '#ED8936');
}

/**
 * @param {string} content
 * @param {string} background
 * @returns {void}
 */
function showToast(content, background) {
    /** @see https://github.com/apvarun/toastify-js */
    Toastify({
        text: DOMPurify.sanitize(content, {ALLOWED_TAGS: ['br', 'span']}),
        gravity: 'bottom',
        position: 'right',
        escapeMarkup: false,
        style: {
            background,
        },
    }).showToast();
}
